<template>
  <div class="tinymce">
    <tinymce
      id='editor'
      ref='tm'
      :other_options='options'
      v-model='content'
      :readonly="disabled"
    ></tinymce>
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
import { UploadFileBase64Request, imageUploadRequest } from "@/api/upload";
export default {
  name: "Tinymce",
  components: {
    tinymce
  },
  props: ["htmlcontent", "disabled"],
  model: {
    prop: "htmlcontent",
    event: "change"
  },
  data() {
    return {
      content: this.htmlcontent,
      options: {
        plugins: [
          "autoresize",
          "advlist autolink lists link image charmap print preview hr anchor pagebreak",
          "searchreplace wordcount visualblocks visualchars code fullscreen",
          "insertdatetime media nonbreaking save table contextmenu directionality",
          "template paste textcolor colorpicker textpattern imagetools toc help emoticons hr codesample"
        ],
        toolbar1:
          "lists image table | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | fontselect | fontsizeselect ",
        font_formats:
          "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';",
        paste_webkit_styles: "all",
        autoresize_on_init: true,
        autoresize_min_height: "300px",
        language_url: "/zh_CN.js",
        images_upload_handler: (blobInfo, success, failure) => {
          console.info(blobInfo);
          let data = new FormData();
          data.append("file", blobInfo.blob());
          imageUploadRequest(data).then(res => {
            console.info(res);
            success(res.data.accessAddress);
          });
     
        }
      }
    };
  },
  watch: {
    content(v) {
      this.$emit("change", v);
    },
    htmlcontent(v) {
      this.content = this.htmlcontent;
    }
  }
};
</script>

<style lang="less">
.is-error {
  .tinymce {
    .mce-tinymce {
      border-color: #f56c6c;
    }
  }
}
</style>
